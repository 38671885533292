<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div class="message-block flex space-x-2 items-center">
                    <i class="el-icon-info" />
                    <p class="text-sm font-regular">{{ generalClarificationsData.financialStatementFromSyna }}</p>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-generalClarifications')">
                    <el-form-item>
                        <RadioButtonList :entries="generalClarificationsData.texts.options" v-model="selectedValue" :horizontal="true" :disabled="inputDisabled" class="text-sm" />
                    </el-form-item>
                    <el-input v-if="selectedValue === 0" type="textarea" :disabled="inputDisabled" v-model="localInputText" class="break-normal custom-textarea" />
                    <el-input v-if="selectedValue === 1" type="textarea" :disabled="inputDisabled" v-model="localInputTextFirstYear" class="break-normal custom-textarea" :rows="6" />
                </div>
            </el-form>
            <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        generalClarificationsData: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            infoContent: `Redovisningsprinciper: K2 (BFNAR 2016:10) kapitel 18 punkt 3.<br />
            <br />Redovisning första året: Ett företag ska enligt vägledningen för K2 (BFNAR 2016:10) punkt 20.10, det första året upplysa om att det allmänna rådet för K2 tillämpas första gången och att detta kan innebära en bristande jämförbarhet mellan räkenskapsåret och det närmast föregående räkenskapsåret. Man kan även välja att inte redovisa jämförelsesiffror utan istället bifoga föregående års årsredovisning. Använd då även redovisningsprincipen Bifogad årsredovisning.`,
            localInputText: this.generalClarificationsData.texts.options[0].text,
            localInputTextFirstYear: this.generalClarificationsData.texts.options[1].text,
            initialInputText: "",
            isInitialInputTextSet: false,
            selectedValue: this.generalClarificationsData.texts.selected,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("../../managementStatement/components/CardEditButtons.vue"),
        InfoButton: () => import("../../managementStatement/components/InfoButton.vue"),
        RadioButtonList: () => import("../../../../components/content/RadioButtonList.vue"),
    },
    watch: {
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 2,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            this.isInitialInputTextSet = false;
            this.generalClarificationsData.texts.options[0].text = this.localInputText;
            this.generalClarificationsData.texts.options[1].text = this.localInputTextFirstYear;
            this.generalClarificationsData.texts.selected = this.selectedValue;
            this.$emit("saveData");
            this.toggleEdit();
            this.$notify.success({ title: 'Sparat "Allmänna upplysningar"' });
        },
        closeModal() {
            this.$emit("close");
            const load = "loading-generalClarifications";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
    },
};
</script>

<style scoped>
.message-block {
    border-left-width: 4px;
    border-color: #1d3f67;
    background-color: #d8e3ef;
    padding: 5px;
    margin-bottom: 20px;
    border-radius: 5px;
    color: #1d3f67;
}
</style>
